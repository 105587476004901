<template>
  <div>
    <sm-loader v-if="getRepaymentsData.loading" />
    <div class="flex flex-col text-14 mb-5 mt-2 font-bold" v-else>
      <h2 class="text-2xl font-bold">Reconciliation</h2>
    </div>

    <div class="border border-solid border-blue-200">
      <datatable
        :data="repayments.data"
        :columns="repayments.columns"
        :filters="repayments.filters"
        :query="repayments.query"
        :loading="repayments.loading"
        :ajax="true"
        :ajax-pagination="true"
        :url="`${this.$baseurl}/admin/reconciliation`"
        :search-field="searchField"
        :onClick="click"
        ref="table"
      >
        <template #header>
          <search-form
            v-model="repayments.query"
            class="mb-5"
            placeholder="Search for user's name"
            @submit="reloadData"
          />

          <div class="flex items-center mb-5">
          <template v-for="(filter, filterIndex) in repayments.filters">
            <div
              :key="filterIndex"
              class="processor-filter-item"
              :class="{ active: currentFilter === filter }"
              @click.prevent="setFilter(filter)"
            >
              {{ filter.title }}
            </div>
          </template>
        </div>
        </template>
      </datatable>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    searchQuery: {
      type: String,
      default: ''
    },
    searchField: {
      type: [HTMLInputElement],
      default: null
    }
  },
  data() {
    return {
      selection: [],
      checkmark: require('@/assets/checkmark-base.svg'),
      currentFilter: null,
      repayments: this.$options.resource([], {
        query: '',
        filters: [
          { name: 'today', title: 'Today' },
          { name: 'yesterday', title: 'Yesterday' },
          { name: 'weekly', title: 'Past 7 Days' },
          { name: 'monthly', title: 'Past Month' },
        ],
        selected: null,
        columns: [
          {
            name: 'user_id',
            th: 'User',
            render: repayment =>
            `${repayment?.user?.name} ${repayment?.user?.last_name || 'N/A'}`
          },
          {
            name: 'credit_card_limit',
            th: 'Credit Limit',
            render: repayment => `₦ ${this.$options.filters.currency(repayment.credit_card_limit)}`
          },
          {
            name: 'daily_spent',
            th: 'Daily Spent',
            render: repayment => {
              let dailySpentAmount = repayment?.personal_transactions.filter(transaction => {
                return this.$moment().isSame(transaction.created_at, 'day');
              });

              dailySpentAmount = dailySpentAmount.reduce((total, transaction) => {
                return total + parseFloat(transaction?.amount)
                }, 0);
              return `₦ ${this.$options.filters.currency(dailySpentAmount)}`;
            }
          },
          {
            name: 'available_balance',
            th: 'Total Billing Spent',
            render: repayment => {
              return `₦ ${this.$options.filters.currency(repayment.available_balance)}`;
            }
          },
          {
            name: 'outstanding_balance',
            th: 'Outstanding Balance',
            render: repayment => {
              let transactions = repayment?.user && this.userTransaction(repayment?.personal_transactions, repayment?.user);

              if (transactions) {
                transactions = transactions.reduce((total, current) => {
                  return total + Number(current?.amount);
                }, 0);
              }

              return `₦ ${this.$options.filters.currency(transactions)}`;
            }
          },
          {
            name: 'available_credit',
            th: 'Available Credit',
            render: repayment => `₦ ${this.$options.filters.currency(repayment.available_credit)}`
          },
          {
            name: 'billing_cycle_count',
            th: 'Repayment Count',
            render: repayment => {
              let billingCycleRepayment = repayment?.user && this.userRepayment(repayment?.user?.personal_credit_card_repayments, repayment?.user);

              return billingCycleRepayment?.length;
            }
          },
          {
            name: 'billing_cycle_repayment',
            th: 'Billing cycle Repayment',
            render: repayment => {
              let billingCycleRepayment = repayment?.user && this.userRepayment(repayment?.user?.personal_credit_card_repayments, repayment?.user);

              if (billingCycleRepayment) {
                billingCycleRepayment = billingCycleRepayment.reduce((total, current) => {
                  return total + Number(current?.amount);
                }, 0);
              } else {
                billingCycleRepayment = 0;
              }


              return `₦ ${this.$options.filters.currency(billingCycleRepayment)}`;
            }
          },
        ],
        successes: [],
        failures: []
      }),
      form: this.$options.basicForm(['status']),
      getRepaymentsData: this.$options.resource({})
    };
  },
  mounted() {
    this.$options.listenForClick.apply(this);
  },
  methods: {
    setUserLastBillingDate(billingDate) {
      let userBillingDate = billingDate;

      userBillingDate = this.$moment().set('date', userBillingDate);

      if (this.$moment(userBillingDate).isAfter(this.$moment())) {
        userBillingDate = userBillingDate.subtract('months', 1).add('days', 1);
      } else {
        userBillingDate = userBillingDate.add('days', 1);
      }

      return userBillingDate;
    },
    userTransaction(transactions, user) {
      if (transactions?.length == 0) {
        return [];
      }

      let userTransactions = transactions;
      let userBillingDate = this.setUserLastBillingDate(user.profile.billing_date);

      userTransactions = userTransactions.filter(transaction => {
        this.$moment(transaction.created_at).isAfter(userBillingDate);
      })

      return userTransactions;
    },
    userRepayment(repayments, user) {
      if (repayments?.length == 0) {
        return [];
      }
      let billingCycleRepayment = repayments;

      let userBillingDate = this.setUserLastBillingDate(user.profile.billing_date);

      billingCycleRepayment = billingCycleRepayment.filter(repayment => {
        return this.$moment(repayment.created_at).isAfter(userBillingDate);
      })

      return billingCycleRepayment;
    },
    click(defaultUser) {
      this.$router.push({
        name: "staff-view",
        params: { staffId: defaultUser?.user_id }
      });
    },
    reloadData() {

      this.$refs.table.loadAjaxData();
    },
    setFilter(filter) {
      if (this.currentFilter === filter) {
        this.currentFilter = null
      }else {
        this.currentFilter = filter
      }

      this.$refs.table.clickedFilter(this.currentFilter)
    },
  }
};
</script>
